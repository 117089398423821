export class CommercialeDistributore {
	/**
	 *
	 */
	constructor() {
		this.itemID = 0;
		this.cancellato = false;
		this.commercialeID = 0;
		this.distributoreID = 0;
		this.startDate = null;
		this.endDate = null;

		this.commissioneTipoID = 0;
		this.commissioneTipo = null;
		this.commissione = 0;
		
		this.dataAssociazione = null;
		
	}

	itemID: number;
	cancellato: boolean | null;
	commercialeID: number;
	distributoreID: number;
	startDate: Date | string | null;
	endDate: Date | string | null;
	dataAssociazione: Date | string | null;

	commissioneTipoID: number | null;
	commissioneTipo: string;
	commissione: number | null;
}
