import { KeyValuePairItem } from "@/models/KeyValuePairItem";
import { filterBy } from "@progress/kendo-data-query";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../models/TextValueItem";

@Component
export default class MultiselectDesc extends Vue {
	@Prop({ default: () => true })
	filterable: boolean;
	@Prop({ default: () => [] })
	data: TextValueItem[];
	@Prop({ default: () => [] })
	value: string[];
	@Prop({ default: () => false })
	disabled: boolean;
	// @Prop({ default: () => [] })
	// selectedValues: number[];
	@Prop({ default: () => "" })
	label: string;
	@Prop({ default: () => "primary" })
	variant: string;
	@Prop({ default: () => "white" })
	textVariant: string;
	@Prop({ default: () => "in" })
	clause: string;
	//clause: string = "in";

	@Prop({ default: () => false })
	hideClauses: boolean;
	

	dataItemsFiltered: TextValueItem[] = [];

	clauseItems: KeyValuePairItem[] = [{key: "in", value: "in"}, {key: "not in", value: "not in"}];

	get myValue() {
		var self = this;
		let values = this.data.filter(function(item) {
			return self.value.indexOf(item.value as string) >= 0 || self.value.indexOf(item.value.toString()) >= 0;
		});
		return values;
	}

	created() {
		this.dataItemsFiltered = this.data.map(x => x);
	}

	onFilterChange(event: any) {
		if (this.data && this.data.length > 0) {
			this.dataItemsFiltered = filterBy(this.data, event.filter);
		}

		return [];
	}

	onSelectionChange(event: any) {
		if (event && event.value) {
			let selectedValues = event.value.map(function(item) {
				return item.value;
			});

			this.$emit("input", selectedValues);
		}
	}

	onClauseChange(event) {
		var item = ((event || {}).target || {}).value as KeyValuePairItem;
		//this.clause = item.key as string;
		this.$emit("clauseChange", item.key as string);
	}

	get ClauseDllValue() {
		if (this.clause == "not in") {
			return this.clauseItems[1];
		}

		return this.clauseItems[0];
	}
}
