import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { ProdottiPolize } from "@/models/prodotti/prodottiPolize";
import permessi from "@/config/permessi";
import { EseguiComandoRequest } from "@/models/comandi/eseguiComandoRequest";

const _ENDPONT = api.endpoint.PRODOTTI.PRODOTTI;
@Component({})
export default class Prodotti extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Prodotti.PRODOTTI];
	}
	@Prop({ default: () => null }) prodottoID: number;
	@Prop({ default: () => null }) fornitoreID: number;
	@Prop({ default: () => null }) tipologiaID: number;
	@Prop({ default: () => false }) prodottoCancellato: boolean;
	editItem: ProdottiPolize = new ProdottiPolize();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = true;
	showHidden: boolean = false;
	showHiddenPolizze: boolean = false;

	tipologieProdotti: TextValueItem[] = [];
	fornitori: TextValueItem[] = [];
	categorie: TextValueItem[] = [];
	prodotto: TextValueItem[] = [];

	tipiPremio: TextValueItem[] = [];

	get showDetail(): boolean {
		return this.prodottoID == null;
	}

	get columns(): Array<any> {
		return [
			{
				field: "itemID",
				title: "ID",
				filterType: "numeric-int",
				width: 80,
				filterable: true,
				hidden: false,
				headerCell: "myHeaderCellTemplate",
				editHide: true
			},
			{ field: "prodottiPacchettiID", title: this.$i18n.t("generico.prodottoID").toString(), filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "pacchetto", title: this.$i18n.t("generico.prodotto").toString(), groupable: true, headerCell: "myHeaderCellTemplate", export: true },
			{
				field: "nomeProdotto",
				title: this.$i18n.t("generico.polizza").toString(),
				width: 200,
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				export: true
			},
			{
				field: "tipologiaID",
				title: this.$i18n.t("generico.tipologia").toString(),
				width: 150,
				values: this.tipologieProdotti,
				itemsForFilter: this.tipologieProdotti,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				hidden: this.prodottoID ? true : false,
				filterType: "select",
				editType: "select",
				editSource: this.tipologieProdotti,
				editDisabled: true,
				export: true
			},
			{
				field: "fornitoreID",
				title: this.$i18n.t("generico.fornitore").toString(),
				width: 150,
				values: this.fornitori,
				itemsForFilter: this.fornitori,
				filterType: "select",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				hidden: this.prodottoID ? true : false,
				editType: "select",
				editSource: this.fornitori,
				editDisabled: true,
				export: true
			},
			{
				field: "categoriaID",
				title: this.$i18n.t("generico.categoria").toString(),
				groupable: true,
				values: this.categorie,
				headerCell: "myHeaderCellTemplate",
				width: 150,
				sortBy: "categoria",
				itemsForFilter: this.categorie,
				filterType: "select",
				editType: "select",
				editSource: this.categorie,
				export: true
			},
			{
				field: "ordine",
				title: this.$i18n.t("generico.ordine").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "numeric-int",
				filterType: "numeric-int",
				width: 100,
			},
			{ field: "codiceAcquistoAutomatico", title: this.$i18n.t("generico.codAutomatico").toString(), width: 150, headerCell: "myHeaderCellTemplate", export: true },
			{
				field: "tipoMarkupID",
				title: this.$i18n.t("generico.tipoMarkup").toString(),
				width: 100,
				values: this.tipiPremio,
				hidden: true,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.tipiPremio
			},
			{ field: "markup", title: this.$i18n.t("generico.markup").toString(), headerCell: "myHeaderCellTemplate", editType: "numeric", headerType: "numeric" },
			{
				field: "logoImageThumb",
				title: this.$i18n.t("generico.logoPiccolo").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\Loghi\\Thumb",
				editField: "logoImageThumb_file",
				export: true,
				width: 150
				// template: function(dataItem) {
				// 	if (dataItem.LogoImageThumb) return "<img src='~/App_Themes/Webins/Immagini/Loghi/Thumb/" + dataItem.LogoImageThumb + "'></img>";
				// 	else return "";
				// }
			},
			{
				field: "logoImage",
				title: this.$i18n.t("generico.logoGrande").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\Loghi",
				editField: "logoImage_file",
				hidden: true
				// template: function(dataItem) {
				// 	if (dataItem.LogoImageThumb) return "<img src='~/App_Themes/Webins/Immagini/Loghi/Thumb/" + dataItem.LogoImageThumb + "'></img>";
				// 	else return "";
				// }
			},
			{
				field: "immagineRicerca",
				title: this.$i18n.t("generico.immagineRicerca").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\CarrelloRicerca",
				editField: "immagineRicerca_file",
				hidden: true
				// template: function(dataItem) {
				// 	if (dataItem.LogoImageThumb) return "<img src='~/App_Themes/Webins/Immagini/Loghi/Thumb/" + dataItem.LogoImageThumb + "'></img>";
				// 	else return "";
				// }
			},
			{
				field: "immagineComplementare",
				title: this.$i18n.t("generico.immagineSuComplementare").toString(),
				headerCell: "myHeaderCellTemplate",
				type: "image",
				pathFallback: "Immagini\\CarrelloComplementari",
				editField: "immagineComplementare_file",
				hidden: true
			},
			// {
			// 	field: "vendibile",
			// 	title: this.$i18n.t("generico.vendibile").toString(),
			// 	width: 150,
			// 	type: "boolean",
			// 	filterType: "boolean",
			// 	headerCell: "myHeaderCellTemplate",
			// 	export: true,
			// 	disabled: true
			// },
			{
				field: "nascosto",
				title: this.$i18n.t("generico.nascosto").toString(),
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true
			},
			{
				field: "polizzaNascosta",
				title: this.$i18n.t("generico.nonAttivo").toString(),
				width: 150,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true,
				editDisabled: this.prodottoCancellato
			},
			{
				field: "mostraNazionalita",
				title: this.$i18n.t("generico.mostraNazionalita").toString(),
				width: 90,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true
			},
			/*{
				field: "mostraNelWidget",
				title: "Mostra nel widget",
				width: 80,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				export: true,
				disabled: true
			},*/
			{
				field: "caricaImmagine",
				title: this.$i18n.t("generico.caricaImmagine").toString(),
				width: 75,
				type: "boolean",
				filterType: "boolean",
				headerCell: "myHeaderCellTemplate",
				hidden: true
			},
			{ field: "_actions", title: " ", width: 100 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "pacchetto", dir: "asc" }];

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.CATEGORIE).then(res => (self.categorie = res));
		var p2 = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPOLOGIE_PRODOTTI).then(res => (self.tipologieProdotti = res));
		var p3 = api.getDatiDropDown(api.endpoint.DROPDOWN.FORNITORI).then(res => (self.fornitori = res));
		var p4 = api.getDatiDropDown(api.endpoint.DROPDOWN.PRODOTTI).then(res => (self.prodotto = res));
		var p5 = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => (self.tipiPremio = res));

		Promise.all([p, p2, p3, p4, p5])
			.then(responses => {
				// if (self.showOnlyActive) {
				// 	self.filter.filters.push({
				// 		field: "vendibile",
				// 		operator: "eq",
				// 		value: "!!true!!"
				// 	});
				// }

				if (!self.showHidden) {
					self.filter.filters.push({
						field: "vendibile",
						operator: "eq",
						value: "!!true!!"
					});
				}

				this.showHiddenPolizze = this.prodottoCancellato;

				if (!self.showHiddenPolizze) {
					self.filter.filters.push({
						field: "polizzaNascosta",
						operator: "neq",
						value: "!!true!!"
					});
				}

				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	// @Watch("showOnlyActive")
	// onShowOnlyActiveChange(newValue: boolean) {
	// 	const field = "vendibile";
	// 	if (newValue) {
	// 		this.filter.filters.push({
	// 			field: field,
	// 			operator: "eq",
	// 			value: "!!true!!"
	// 		});

	// 		this.setDefaultPageSize();
	// 		this.getData();
	// 	} else {
	// 		var findFilterIndex = this.filter.filters.findIndex(function(item) {
	// 			return item.field === field;
	// 		});

	// 		this.filter.filters.splice(findFilterIndex, 1);

	// 		this.setDefaultPageSize();
	// 		this.getData();
	// 	}
	// }

	@Watch("showHidden")
	onShowHiddenChange(newValue: boolean) {
		const field = "vendibile";
		if (newValue) {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();			
		} else {
			this.filter.filters.push({
				field: field,
				operator: "eq",
				value: "!!true!!"
			});

			this.setDefaultPageSize();
			this.getData();
		}
	}

	@Watch("showHiddenPolizze")
	onShowHiddenPolizzaChange(newValue: boolean) {
		const field = "polizzaNascosta";
		if (newValue) {
			var findFilterIndex = this.filter.filters.findIndex(function(item) {
				return item.field === field;
			});

			this.filter.filters.splice(findFilterIndex, 1);

			this.setDefaultPageSize();
			this.getData();			
		} else {
			this.filter.filters.push({
				field: field,
				operator: "neq",
				value: "!!true!!"
			});

			this.setDefaultPageSize();
			this.getData();
		}
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.prodottoID}`);
	}

	exportExcel() {
		this.exportGridExcel(_ENDPONT);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${self.poliza}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.prodotti")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new ProdottiPolize();
		newItem.prodottoID = this.prodottoID;
		newItem.fornitoreID = this.fornitoreID;
		newItem.tipologiaID = this.tipologiaID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new ProdottiPolize(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;

		var tempItem = self.editItem as any;
		tempItem.vendibile = tempItem.nascosto ? false : true;

		api.setProdottoPoliza(self.editItem)
			.then(res => {
				this.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.prodotti") }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});

				self.getData();
				self.showEditModal = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	duplicaProdotto(item: ProdottiPolize) {
		var self = this;

		var newitem = new ProdottiPolize();

		newitem.itemID = item.itemID;
		newitem.pacchetto = item.pacchetto + " - " + this.$i18n.t("generico.copia").toString();
		newitem.prodottoID = item.prodottoID;

		var msg = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.nomeProdotto}</h5>${self.$i18n.t("messaggio.confermaDuplicazione")}` } });
		self.$bvModal
			.msgBoxConfirm([msg], {
				title: this.$i18n.t("generico.duplica").toString() + " " + this.$i18n.t("generico.prodotto").toString(),
				okVariant: "warning",
				okTitle: self.$i18n
					.t("btn.si")
					.toString()
					.toUpperCase(),
				cancelTitle: self.$i18n
					.t("btn.no")
					.toString()
					.toUpperCase(),
				cancelVariant: "outline-theme",
				footerClass: "",
				headerBgVariant: "warning"
				// centered: true
			})
			.then(value => {
				if (value) {
					api.chiamataPost(`${_ENDPONT}/clone`, newitem)
						.then(res => {
							//self.getData();
							if (res && res.data) {
								self.eseguiSincronizzaProdotto(res.data as number);
							}
						})
						.catch(err => console.log(err));
				}
			})
			.catch(err => {
				// An error occurred
			});
	}

	eseguiSincronizzaProdotto(pacchettoID: number) {
		var self = this;
		let cmd = "CreaSchedaProdotto";
		let data: EseguiComandoRequest = {
			comando: cmd,
			elenco: [],
			parametri: [pacchettoID]
		};
		api.eseguiComando(data)
			.then(res => {
				self.$bvToast.toast(this.$i18n.t("generico.duplicazioneOk").toString(), {
					variant: "success",
					title: this.$i18n.t("generico.azioneeseguita").toString(),
					solid: true
				});
				self.getData();
			})
			.catch(err => {
				self.$bvToast.toast(this.$i18n.t("generico.erroreGenerico").toString(), {
					variant: "error",
					title: this.$i18n.t("generico.errore").toString(),
					solid: true
				});
			});
	}
}
