// import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import api from "@/services/api";
import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { TextValueItem } from "../../../models/TextValueItem";
import { ElencoAssociazione } from "../../../models/commerciali/elencoAssociazione";
import { DistributoreAssociatoAnagrafica } from "../../../models/commerciali/DistributoreAssociatoAnagrafica";
import { DistributoreAssociatoAnagraficaEdit } from "@/models/commerciali/DistributoreAssociatoAnagraficaEdit";
import permessi from "@/config/permessi";

const _ENDPONT = api.endpoint.COMMERCIALI_DISTRIBUTORI_ASSOCIATI;

@Component({})
export default class DistributoriAssociati extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Commerciali.COMMERCIALI];
	}
	@Prop({ default: () => null }) itemID: number;
	editItem: DistributoreAssociatoAnagraficaEdit = new DistributoreAssociatoAnagraficaEdit();
	showEditModal: boolean = false;
	currentItem: any = {};
	showOnlyActive: boolean = false;

	tipiPremio: TextValueItem[] = [];

	actionsToDo: TextValueItem[] = [
		{ value: null, text: "Esegui su selezione" },
		{ value: 1, text: "Rimuovi selezionati" }
	];
	selectedActionToDo: number = null;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },

			{
				field: "ragioneSociale",
				title: this.$i18n.t("generico.ragioneSociale").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				editDisabled: true
			},
			{
				field: "tipoDistributore",
				title: this.$i18n.t("generico.tipo").toString(),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				width: this.setColPercent(10),
				filterable: true,
				export: true,
				editDisabled: true
			},
			{
				field: "commissioneTipoID",
				title: this.$i18n.t("generico.tipoCommissione").toString(),
				width: this.setColPercent(10),
				values: this.tipiPremio,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				itemsForFilter: this.tipiPremio,
				filterType: "select",
				editType: "select",
				editSource: this.tipiPremio
			},
			{ field: "tipoPremio", title: this.$i18n.t("generico.tipoCommissione").toString(), hidden: true, editHide: true, export: true },
			{
				field: "commissione",
				title: this.$i18n.t("generico.commissione").toString(),
				width: this.setColPercent(10),
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				//type: "numeric",
				type: "renderfunction", 
				renderFunction: this.renderCommissione,
				headerType: "numeric",
			},
			{
				field: "provincia",
				title: this.$i18n.t("generico.provincia").toString(),
				groupable: true,
				width: this.setColPercent(8),
				headerCell: "myHeaderCellTemplate",
				editDisabled: true,
				export: true
			},
			{ field: "regione", title: "Regione", groupable: true, width: this.setColPercent(8), headerCell: "myHeaderCellTemplate", editDisabled: true, export: true },
			{
				field: "dataInizioRapporto",
				title: this.$i18n.t("generico.inizioRapporto").toString(),
				format: "{0:dd/MM/yyyy}",
				width: this.setColPercent(10),
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},
			{
				field: "dataFineRapporto",
				title: this.$i18n.t("generico.fineRapporto").toString(),
				format: "{0:dd/MM/yyyy}",
				width: this.setColPercent(10),
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate
			},
			/*{
				field: "dataAssociazione",
				title: "Data Associazione",
				width: 100,
				format: "{0:dd/MM/yyyy}",
				groupable: true,
				editDisabled: this.isNewItem ? true : false,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "date",
				headerType: "daterange",
				exportRenderFunction: this.exportRenderDate,
				validation: { required: true, msg: this.$i18n.t("generico.campoRichiesto").toString() + ": " + "Data Associazione" }
			},*/
			{ field: "cancellato", title: this.$i18n.t("generico.nonAttivo").toString(), disabled: true, hidden: true, width: this.setColPercent(8), headerCell: "myHeaderCellTemplate", type: "boolean", filterType: "boolean", export: true },

			
			{
				field: "distributoreCancellato",
				title: "Distr Non Attivo",
				width: this.setColPercent(8),
				headerCell: "myHeaderCellTemplate",
				type: "boolean",
				filterType: "boolean",
				editHide: true,
				editDisabled: true,
				disabled: true
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" },
			{ field: "_ck_select", title: " ", width: 50, cell: "defaultCellTemplate", type: "ck_select", filterable: false, headerCell: "myHeaderCellTemplate", headerType: "boolean" }
		];
	}

	sort: any = [{ field: "ragioneSociale", dir: "asc" }];

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;

		Promise.all([api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => (self.tipiPremio = res))])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	mounted() {}

	exportExcel() {
		this.exportGridExcel(`${_ENDPONT}/${this.itemID}`);
	}

	getData() {
		var self = this;
		this.getGridData(`${_ENDPONT}/${this.itemID}`);
	}
	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.ragioneSociale}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.distributore")]).toString();
		self.deleteGridRow(`${_ENDPONT}/single`, item.itemID, title, [message]);
	}
	onEdit(item: any) {
		var self = this;

		self.editItem = Object.assign(new DistributoreAssociatoAnagraficaEdit(), item);
		self.editSubTitle = item.ragioneSociale;
		self.editTitle = item.partitaIva || item.codiceFiscale;
		self.showEditModal = true;
	}
	editTitle: string = null;
	editSubTitle: string = null;

	onSaveEdit() {
		var self = this;

		api.updateAnagraficaAssociazioneCD(self.editItem)
			.then(res => {
				this.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.distributoreAssociato") }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});

				self.getData();
				self.showEditModal = false;
			})
			.catch(err => {
				console.log(err);
			});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	eseguiSuSelezione() {
		var self = this;
		if (this.selectedActionToDo) {
			if (this.gridData && this.gridData.data && this.gridData.data.length > 0) {
				let elementToDoAction: number[] = (this.gridData.data as DistributoreAssociatoAnagrafica[])
					.filter(function(item) {
						return item._ck_select == true;
					})
					.map(function(el) {
						return el.distributoreID;
					});

				if (elementToDoAction.length > 0) {
					let message = self.$createElement("div", { domProps: { innerHTML: `${self.$i18n.t("messaggio.confermaDisassociazione")}` } });
					let title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.associazioneCommercialiDistributori")]).toString();

					self.$bvModal
						.msgBoxConfirm([message], {
							title: title,
							okVariant: "warning",
							okTitle: self.$i18n
								.t("btn.si")
								.toString()
								.toUpperCase(),
							cancelTitle: self.$i18n
								.t("btn.no")
								.toString()
								.toUpperCase(),
							cancelVariant: "outline-theme",
							footerClass: "",
							headerBgVariant: "warning"
						})
						.then(value => {
							if (value) {
								let data: ElencoAssociazione = {
									commercialeID: self.itemID,
									distributori: elementToDoAction,
									dataFineRapporto: null,
									dataInizioRapporto: null
								};

								api.deleteAssociazioniCommercialiDistributori(data)
									.then(res => {
										this.$bvToast.toast(this.$i18n.t("generico.cmdeseguito").toString(), {
											variant: "success",
											title: this.$i18n.t("generico.azioneeseguita").toString(),
											solid: true
										});

										self.getData();
									})
									.catch(err => {
										console.log(err);
									});
							}
						})
						.catch(err => {
							console.log(err);
						});
				} else {
					this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
						variant: "warning",
						title: this.$i18n.t("generico.warning").toString(),
						solid: true
					});
				}
			} else {
				this.$bvToast.toast(this.$i18n.t("generico.nodataforactions").toString(), {
					variant: "warning",
					title: this.$i18n.t("generico.warning").toString(),
					solid: true
				});
			}
		} else {
			this.$bvToast.toast(this.$i18n.t("generico.noactionselected").toString(), {
				variant: "warning",
				title: this.$i18n.t("generico.warning").toString(),
				solid: true
			});
		}
	}
}
