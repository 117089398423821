import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop } from "vue-property-decorator";
import { TextValueItem } from "../../../models/TextValueItem";
import api from "@/services/api";
import { CommissioniProdottiItem } from "@/models/prodotti/commissioniProdottiItem";
import permessi from "@/config/permessi";
import editForm from "@/components/grid/edit/editForm.vue";

const _ENDPONT = api.endpoint.PRODOTTI.COMMISSIONI_PRODOTTI;
@Component({})
export default class CommissioniProdotti extends gridComponentBase {
	get PERMESSI(): string[] { return [permessi.BK.Prodotti.PRODOTTI]; }
	@Prop({ default: () => null }) pacchettoID: number;
	editItem: CommissioniProdottiItem = new CommissioniProdottiItem();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	beneficiari: TextValueItem[] = [];
	tipiPremio: TextValueItem[] = [];

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "pacchettoID", title: "ID", width: 20, filterable: false, hidden: true, headerCell: "myHeaderCellTemplate", editHide: true },
			{
				field: "beneficiarioID",
				title: this.$i18n.t("generico.beneficiario").toString(),
				values: this.beneficiari,
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.beneficiari,
				filterType: "select",
				itemsForFilter: this.beneficiari,
				sortBy: "beneficiario"
			},

			{
				field: "tipoValoreID",
				title: this.$i18n.t("generico.tipoValore").toString(),
				values: this.tipiPremio,
				groupable: true,
				sortBy: "tipoPremio",
				headerCell: "myHeaderCellTemplate",
				editType: "select",
				editSource: this.tipiPremio,
				filterType: "select",
				itemsForFilter: this.tipiPremio
			},
			{ field: "valore", title: this.$i18n.t("generico.valore").toString(), filterType: "numeric", headerCell: "myHeaderCellTemplate" },
			{ field: "startDate", title: this.$i18n.t("generico.startDate").toString(), format: "{0:dd/MM/yyyy}", headerCell: "myHeaderCellTemplate", type: "date", filterType: "daterange",
			validation: { required: true, msg: this.$i18n.t("generico.campoRichiesto").toString() + ": " + this.$i18n.t("generico.startDate").toString() } 
		
		},
			{ field: "endDate", title: this.$i18n.t("generico.endDate").toString(), format: "{0:dd/MM/yyyy}", headerCell: "myHeaderCellTemplate", type: "date", filterType: "daterange" },
			{ field: "istanteModifica", title: "Ultima Modifica", width: 90, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true, type: "datetime"},
			{ field: "utenteModifica", title: "Utente", width: 90, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true},
			{ field: "note", title: "Note", type:"textarea", width: 90, hidden: true, headerCell: "myHeaderCellTemplate", editDisabled: true},
			
			{
				field: "_actions",
				title: " ",
				width: (50 + (this.canDelete ? 50 : 0)),
				cell: "defaultCellTemplate",
				type: "actions_list",
				filterable: false,
				headerCell: "myHeaderCellTemplate"
			}
		];
	}

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		var p = api.getDatiDropDown(api.endpoint.DROPDOWN.BENEFICIARI).then(res => (self.beneficiari = res));
		var p2 = api.getDatiDropDown(api.endpoint.DROPDOWN.TIPI_PREMIO).then(res => self.tipiPremio = res);

		Promise.all([p, p2])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}/${this.pacchettoID}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.commissioni")]).toString();
		self.deleteGridRow(_ENDPONT, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new CommissioniProdottiItem();
		newItem.pacchettoID = this.pacchettoID;

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		self.editItem = Object.assign(new CommissioniProdottiItem(), item);
		self.editSubTitle = "";
		self.editTitle = item.itemID;
		self.showEditModal = true;
	}

	onSaveEdit() {
		var self = this;
		var err = (this.$refs.editForm as editForm).validate();
		if (err) {
			var msgs = [];
			for (var i = 0; i < err.length; i++) msgs.push(this.$createElement("div", null, err[i]));

			self.$bvToast.toast(msgs, {
				variant: "danger",
				title: self.$i18n.t("msg.erroreGenerico").toString(),
				solid: true
			});
			return;
		}
		
		var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
		var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.commissioni") }).toString();
		self.setGridRow<CommissioniProdottiItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
			self.showEditModal = false;
		});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}
}
