import permessi from "@/config/permessi";
import { ElencoInviiItem } from "@/models/reportFornitori/elencoInviiItem";
import apiFile from "@/services/apiFile";
import { Component, Prop } from "vue-property-decorator";
import gridComponentBase from "../../../components/grid/gridComponentBase.vue";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";

@Component
export default class AdesioniJSONReportFornitorePage extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Vendite.REPORTFORNITORE];
	}
	@Prop({ default: () => "" })
	endpoint: string;

	@Prop({ default: () => false })
	showCouponID: boolean;
	
	@Prop({ default: () => 0 })
	itemID: number;
	
	azioni: TextValueItem[] = [{value: 1, text:"Creazione"}, {value: 2, text:"Modifica"}, {value: 3, text:"Cancellazione"}];
	esiti: TextValueItem[] = [{value: 0, text:"Nessuna risposta"}, {value: 1, text:"OK"}, {value: 2, text:"ERRORE"}];
	stati: TextValueItem[] = [{value: 0, text:"In attesa di invio"}, {value: 1, text:"Inviato"}];

	
	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", width: 20, filterable: false, hidden: true, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", editHide: true },
			{ field: "adesioneID", title: "Adesione", width: 200, filterType: "numeric-int", cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true, },
			{ field: "couponID", title: "Coupon ID", width: 200, cell: "defaultCellTemplate", headerCell: "myHeaderCellTemplate", export: true, hidden: !this.showCouponID },
			{
				field: "istanteInserimento",
				title: "Data Creazione",
				width: 200,
				format: "{0:dd/MM/yyyy HH:mm:ss}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				addUtcOffset: true,
				type: "datetime",
				headerType: "datetimerange",
				exportRenderFunction: this.exportRenderDateTime
			},

			{
				field: "ultimoInvio",
				title: "Ultimo Invio",
				width: 200,
				format: "{0:dd/MM/yyyy HH:mm:ss}",
				groupable: true,
				headerCell: "myHeaderCellTemplate",
				filterable: true,
				export: true,
				type: "datetime",
				headerType: "datetimerange",
				addUtcOffset: true,
				exportRenderFunction: this.exportRenderDateTime
			},

			{
				field: "esito",
				title: "Esito",
				width: 100,
				values: this.esiti,
				editSourceValue: "value",
				headerCell: "myHeaderCellTemplate",
				filterType: "select",
				itemsForFilter: this.esiti,
				export: true
			},

			{
				field: "azione",
				title: "Azione",
				width: 100,
				values: this.azioni,
				editSourceValue: "value",
				headerCell: "myHeaderCellTemplate",
				filterType: "select",
				itemsForFilter: this.azioni,
				export: true
			},

			{
				field: "stato",
				title: "Stato",
				width: 100,
				values: this.stati,
				editSourceValue: "value",
				headerCell: "myHeaderCellTemplate",
				filterType: "select",
				itemsForFilter: this.stati,
				export: true
			},

			{ field: "risposta", title: "Risposta", headerCell: "myHeaderCellTemplate", filterable: true, export: true },
			
			{ field: "_btn", title: " ", width: 100 , cell: "btnCambiaStatoCellTemplate", filterable: false, headerCell: "myHeaderCellTemplate" }
		
		];
	}

	sort: any = [{ field: "istanteInserimento", dir: "desc" }];

	created() {
		this.getData();
	}

	exportExcel() {
		this.exportGridExcel(this.endpoint);
	}

	refreshData() {
		this.getData();
	}

	getData() {
		this.getGridData(this.endpoint);
	}

	onForzaEsitoOk(item){
		if(!confirm("Confermare la forzatura dell'esito a OK?"))
			return;
		var self = this;
		api.chiamataPost(`reportfornitore/adesionijson/${item.itemID}`,{}).then(res =>{

			self.$bvToast.toast("Esito impostato correttamente ad OK", {
				variant: "success",
				title: "Esito impostato",
				solid: true
			});

			self.getData();
		}).catch(err =>{
			
			self.$bvToast.toast("Si e' verificato un errore", {
				variant: "danger",
				title: self.$i18n.t('msg.erroreGenerico').toString(),
				solid: true
			});
		});
	}

}
