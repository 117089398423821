import Vue from "vue";
import Multiselect from "vue-multiselect";
//import "@progress/kendo-theme-default/dist/all.css";
import "@progress/kendo-theme-bootstrap/dist/all.scss";
// import { Grid } from '@progress/kendo-vue-grid';
// import { Grid } from '../src/customPlugin/kendo/kendo-vue-grid/src/main';
import { Grid, GridToolbar, FooterRow, Footer } from './customPlugin/kendo/kendo-vue-grid/dist/npm/main';
import { IntlProvider } from "@progress/kendo-vue-intl";


import defaultLayout from "./layouts/default.vue";
import subMenuLayout from "./layouts/subMenu.vue";
import emptyLayout from "./layouts/empty.vue";
import plashScreen from "./pages/plashScreen.vue";
import topNavBar from "./components/topNavBar.vue";
import subTopNav from "./components/subTopNav.vue";
import footer from "./components/footerBottom.vue";
import cellTemplate from "./components/grid/cellTemplate/cellTemplate.vue";
import actionCellTemplate from "./components/grid/cellTemplate/actionCellTemplate.vue";
import headerCellTemplate from "./components/grid/cellTemplate/headerTemplate.vue";
import editForm from "./components/grid/edit/editForm.vue";
import appImage from "./components/appImage.vue";
// import { ButtonsInstaller } from '@progress/kendo-buttons-vue-wrapper'
import { ButtonsInstaller } from '@progress/kendo-buttons-vue-wrapper'
import VSelect from '@alfsnd/vue-bootstrap-select';
import VueLodash from "vue-lodash";
import lodash from 'lodash';
import VJsoneditor from 'v-jsoneditor/src/index'
// directives

import dir_auth_image from "./directives/vue-auth-image";
import distributoriCommerciali from './pages/anagrafica/distributori/distributoriCommerciali.vue';
import AppDatePicker from "./components/appDatePicker.vue";
import AssociaPermessi from "./components/associa-permessi.vue";
import CreditiDistributori from "./pages/anagrafica/distributori/creditiDistributori.vue";
import datePicker from 'vue-bootstrap-datetimepicker';
import { DateinputsInstaller } from "@progress/kendo-dateinputs-vue-wrapper";
// import { Editor } from '@progress/kendo-editor-vue-wrapper'
// import { EditorTool } from '@progress/kendo-editor-vue-wrapper'
import { EditorInstaller } from '@progress/kendo-editor-vue-wrapper'
import GruppiStato from './pages/anagrafica/fornitori/gruppiStato.vue';
import DistributoriAssociati from './pages/commerciale/anagraficaCommerciale/distributoriAssociati.vue';
import SelezionaDistributori from "./pages/commerciale/anagraficaCommerciale/selezionaDistributori.vue";
import CommissioniCommercialiPage from "./pages/commerciale/commissioni/commissioniCommerciali.vue";
import CommissioniDistributoriPage from "./pages/commerciale/commissioni/commissioniDistributori.vue";
import CommissioniPolizzeCommercialiPage from "./pages/commerciale/commissioni/commissioniPolizzeCommerciali.vue";
import CommissioniProdottiCommercialiPage from "./pages/commerciale/commissioni/commissioniProdottiCommerciali.vue";
import CommissioniProdottiDistributoriPage from "./pages/commerciale/commissioni/commissioniProdottiDistributori.vue";
import CommissioniPolizzeDistributoriPage from "./pages/commerciale/commissioni/commissioniPolizzeDistributori.vue";
import { AutoComplete, ComboBox, DropDownList, MultiSelect } from '@progress/kendo-vue-dropdowns';
import { Dialog, DialogActionsBar, Window } from '@progress/kendo-vue-dialogs';
import ParametriPolize from './pages/gestioneProdotti/polize/parametriPolize.vue';
import CommissioniPolize from './pages/gestioneProdotti/polize/commissioniPolize.vue';
import GridFornitoriReport from "./pages/gestioneVendite/reportFornitore/gridReportFornitori.vue";
import GridFornitoriReportJSON from "./pages/gestioneVendite/reportFornitore/gridReportJSONFornitori.vue";
import StoricoInviiReportFornitorePage from "./pages/gestioneVendite/reportFornitore/storicoInviiReportFornitore.vue";
import AdesioniJSONReportFornitorePage from "./pages/gestioneVendite/reportFornitore/adesioniJSONReportFornitore.vue";
import ProdottiPolize from './pages/gestioneProdotti/polize/prodottiPolize.vue';
import Prodotti from './pages/gestioneProdotti/prodotti/prodotti.vue';
import GridStoricoDettaglioPage from "./pages/gestioneVendite/reportFornitore/gridStoricoDettaglio.vue";
import GridStoricoDettaglioJSONPage from "./pages/gestioneVendite/reportFornitore/gridStoricoDettaglioJSON.vue";
import editFormGrop from "./components/grid/edit/editFormGroup.vue";
import editFormGroupParametroValore from "./components/grid/edit/editFormGroupParametroValore.vue";
import utentiPage from "./pages/anagrafica/utenti/utenti.vue";
import DocumentiPolize from './pages/gestioneProdotti/polize/documentiPolize.vue';
import DescrizioniPolize from './pages/gestioneProdotti/polize/descrizioniPolize.vue';
import AccediComeModalPage from './components/accedi-come.vue';
import TestiCarrelloPolize from './pages/gestioneProdotti/polize/testiCarrelloPolize.vue';
import ExtraInfoPolize from './pages/gestioneProdotti/polize/extraInfoPolize.vue';
import GaranziePolizze from './pages/gestioneProdotti/polize/garanziePolizze.vue';
import FattoriPolizze from './pages/gestioneProdotti/polize/fattoriPolizze.vue';
import DescrizioniProdotti from './pages/gestioneProdotti/prodotti/descrizioniProdotti.vue';
import ParametriProdotti from './pages/gestioneProdotti/prodotti/parametriProdotti.vue';
import CommissioniProdotti from './pages/gestioneProdotti/prodotti/commissioniProdotti.vue';
import DocumentiProdotti from './pages/gestioneProdotti/prodotti/documentiProdotti.vue';
import MultiselectDesc from './components/multiselect-desc.vue';
import DistributoriAssociatiProdotti from './pages/gestioneProdotti/prodotti/distributoriAssociatiProdotti.vue';
import SelezionaDistributoriProdotti from './pages/gestioneProdotti/prodotti/selezionaDistributoriProdotti.vue';
import TestiCarrelloProdotti from "./pages/gestioneProdotti/prodotti/testiCarrelloProdotti.vue";
import ProdottiCorrelati from "./pages/gestioneProdotti/prodotti/prodottiCorrelati.vue";
import ProdottiUpgrade from "./pages/gestioneProdotti/prodotti/prodottiUpgrade.vue";
import SchedaProdotto from "./pages/gestioneProdotti/prodotti/schedaProdotto.vue";
import ProdottiAssociatiDistributori from "./pages/anagrafica/distributori/prodottiAssociatiDistributori.vue";
import ProdottiWidgetAssociatiDistributori from "./pages/anagrafica/distributori/prodottiWidgetAssociatiDistributori.vue";
import { Upload } from "@progress/kendo-vue-upload";
import AssStatoGruppoStato from "./pages/anagrafica/fornitori/assStatoGruppoStato.vue";
import GruppiStatoPolize from "./pages/gestioneProdotti/polize/gruppiStatoPolize.vue";
import iTipologieDistributore from "./pages/amministrazione/customTable/tipiDistributore/tipologieDistributore.vue";
import GridAgenzieAssociazioni from "./pages/amministrazione/customTable/agenzie-associazioni/agenzie-associazioni.vue";
import CodiciPagamento from "./pages/amministrazione/customTable/codiciPagamento/codiciPagamento.vue";
import GruppiStati from "./pages/amministrazione/customTable/gruppiStati/gruppiStati.vue";
import Nazionalita from "./pages/amministrazione/customTable/nazionalita/nazionalita.vue";
import PacchettiCategorie from "./pages/amministrazione/customTable/pacchettiCategorie/pacchettiCategorie.vue";
import Stati from "./pages/amministrazione/customTable/stati/stati.vue";
import ExtraInfo from "./pages/amministrazione/customTable/extraInfo/extraInfo.vue";
import TipiDistributori from "./pages/amministrazione/customTable/tipiDistributore/tipiDistributore.vue";
import CommissioneBeneficiari from "./pages/amministrazione/customTable/commissioniBeneficiari/commissioniBeneficiari.vue";
import Contraenti from "./pages/amministrazione/customTable/contraenti/contraenti.vue";
import Impostazioni from "./pages/amministrazione/customTable/impostazioni/impostazioni.vue";
import PopupCarrello from "./pages/amministrazione/customTable/popup-carrello/popupCarrello.vue";
import TipologieProdotti from "./pages/amministrazione/customTable/tipologie-prodotti/tipologieProdotti.vue";
import Eta from "./pages/amministrazione/customTable/eta/eta.vue";
import FasceEta from "./pages/amministrazione/customTable/fasceEta/fasceEta.vue";
import SchedaPolizza from "./pages/gestioneProdotti/polize/schedaPolizza.vue";
import Parametri from "./pages/amministrazione/customTable/parametri/parametri.vue";
import TipiParametri from "./pages/amministrazione/customTable/parametri/tipiParametri.vue";
import TipiParametriValori from "./pages/amministrazione/customTable/parametri/tipiParametriValori.vue";
import AssociaStati from "./pages/amministrazione/customTable/gruppiStati/associaStati.vue";
import AssociazioneStati from "./pages/amministrazione/customTable/gruppiStati/associazioneStati.vue";
import AssociazioneStatiFornitore from "./pages/anagrafica/fornitori/associazioneStati.vue";
import AssociaStatiFornitore from "./pages/anagrafica/fornitori/associaStati.vue";
import AssociazioneStatiPolizza from "./pages/gestioneProdotti/polize/associazioneStatiPolizza.vue";
import AssociaStatiPolizza from "./pages/gestioneProdotti/polize/associaStatiPolizza.vue";

import GruppiNazionalita from "./pages/amministrazione/customTable/gruppiNazionalita/gruppiNazionalita.vue";
import AssociaNazionalita from "./pages/amministrazione/customTable/gruppiNazionalita/associaNazionalita.vue";
import AssociazioneNazionalita from "./pages/amministrazione/customTable/gruppiNazionalita/associazioneNazionalita.vue";


import InfoProdottoPreventivo from "./components/carrello/infoProdottoPreventivo.vue";
import GrigliaPartecipanti from "./components/carrello/grigliaPartecipanti.vue";
import GrigliaUlterioriDati from "./components/carrello/grigliaUlterioriDati.vue";
import Dichiarazioni from "./components/carrello/dichiarazioni.vue";
import Complementari from "./components/carrello/complementari.vue";
import CarrelloDatePicker from "./components/carrello/carrelloDatePicker.vue";
import CarrelloDropDown from "./components/carrello/carrelloDropdown.vue";
import PreviewPratica from "./pages/amministrazione/praticheMultiple/preview.vue";
import columnsMappingBase from "./pages/amministrazione/praticheMultiple/columnsMappingBase.vue";
import InformazioniGeneriche from "./pages/amministrazione/customTable/informazioni/informazioni.vue";
import ConfigurazioneModificaPraticheDettaglio from "./pages/amministrazione/configurazioneModificaPratiche/configurazioneModificaPraticheDettaglio.vue";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import FilterMobile from "./components/filterMobile.vue";
import GruppiNazionalitaFornitori from "./pages/anagrafica/fornitori/gruppiNazionalita.vue";
import AssociazioneNazionalitaFornitore from "./pages/anagrafica/fornitori/associazioneNazionalita.vue";
import AssociaNazionalitaFornitore from "./pages/anagrafica/fornitori/associaNazionalita.vue";
import ricercaGaranzieCoupon from "./pages/anagrafica/coupon/ricercaGaranzieCoupon.vue";

class main_registraion {
	init = function (): void {
		Vue.component("upload", Upload);
		Vue.component("top-nav-bar", topNavBar);
		Vue.component("sub-top-nav", subTopNav);
		Vue.component("app-footer", footer);
		Vue.component("default-layout", defaultLayout);
		Vue.component("sub-menu-layout", subMenuLayout);
		Vue.component("empty-layout", emptyLayout);
		Vue.component("plash-screen", plashScreen);
		//Vue.component("multiselect", Multiselect);
		Vue.component("associa-permessi", AssociaPermessi);
		Vue.component("grid", Grid);
		Vue.component("footerrow", FooterRow);
		Vue.component("grid-toolbar", GridToolbar);
		Vue.component("intl-provider", IntlProvider);
		Vue.component("cell-template", cellTemplate);
		Vue.component("action-cell-template", actionCellTemplate);
		Vue.component("header-cell-template", headerCellTemplate);
		Vue.component("edit-form", editForm);
		Vue.component("edit-form-group", editFormGrop);
		Vue.component("edit-form-group-parametro-valore", editFormGroupParametroValore);
		Vue.component("app-image", appImage);
		Vue.component("app-date-picker", AppDatePicker);
		Vue.component("distributori-commerciali", distributoriCommerciali);
		Vue.component("crediti-distributori", CreditiDistributori);
		Vue.component("gruppi-stato-fornitore", GruppiStato);
		Vue.component("ricerca-garanzie-coupon", ricercaGaranzieCoupon);
		Vue.component("gruppi-nazionalita-fornitore", GruppiNazionalitaFornitori);
		Vue.component("gruppi-stato-polize", GruppiStatoPolize);
		Vue.component("distributori-associati", DistributoriAssociati);
		Vue.component("seleziona-distributori", SelezionaDistributori);
		Vue.component("commissioni-commerciali", CommissioniCommercialiPage);
		Vue.component("commissioni-distributori", CommissioniDistributoriPage);
		Vue.component("commissioni-polizze-commerciali", CommissioniPolizzeCommercialiPage);
		Vue.component("commissioni-prodotti-commerciali", CommissioniProdottiCommercialiPage);
		Vue.component("commissioni-prodotti-distributori", CommissioniProdottiDistributoriPage);
		Vue.component("commissioni-polizze-distributori", CommissioniPolizzeDistributoriPage);
		Vue.component("tipologie-distributore", iTipologieDistributore);
		Vue.component("agenzie-associazioni", GridAgenzieAssociazioni);
		Vue.component("tipi-distributori", TipiDistributori);
		Vue.component("codici-pagamento", CodiciPagamento);
		Vue.component("extra-info", ExtraInfo);
		Vue.component("gruppi-stati", GruppiStati);
		Vue.component("gruppi-nazionalita", GruppiNazionalita);
		Vue.component("nazionalita", Nazionalita);
		Vue.component("pacchetti-categorie", PacchettiCategorie);
		Vue.component("commissioni-beneficiari", CommissioneBeneficiari);
		Vue.component("contraenti", Contraenti);
		Vue.component("stati", Stati);
		Vue.component("utenti", utentiPage);
		Vue.component("autocomplete", AutoComplete);
		Vue.component("combobox", ComboBox);
		Vue.component("dropdownlist", DropDownList);
		Vue.component("multiselect", MultiSelect);
		Vue.component("k-dialog", Dialog);
		Vue.component("k-window", Window);
		Vue.component("dialog-actions-bar", DialogActionsBar);
		Vue.component("grid-report-fornitori", GridFornitoriReport);
		Vue.component("grid-report-json-fornitori", GridFornitoriReportJSON);
		Vue.component("grid-storico-invii-report-fornitori", StoricoInviiReportFornitorePage);
		Vue.component("adesioni-json-report-fornitori", AdesioniJSONReportFornitorePage);
		Vue.component("grid-storico-dettaglio", GridStoricoDettaglioPage);
		Vue.component("grid-storico-dettaglio-json", GridStoricoDettaglioJSONPage);
		Vue.component("accedi-come", AccediComeModalPage)

		Vue.component("parametri-polize", ParametriPolize);
		Vue.component("commissioni-polize", CommissioniPolize);
		Vue.component("prodotti-polize", ProdottiPolize);
		Vue.component("prodotti", Prodotti);
		Vue.component("documenti-polize", DocumentiPolize);
		Vue.component("descrizioni-polize", DescrizioniPolize);
		Vue.component("testicarrello-polize", TestiCarrelloPolize);
		Vue.component("extra-info-polize", ExtraInfoPolize);
		Vue.component("garanzie-polizze", GaranziePolizze);
		Vue.component("fattori-polizze", FattoriPolizze);
		Vue.component("descrizioni-prodotti", DescrizioniProdotti);
		Vue.component("parametri-prodotti", ParametriProdotti);
		Vue.component("commissioni-prodotti", CommissioniProdotti);
		Vue.component("documenti-prodotti", DocumentiProdotti);
		Vue.component("distributori-associati-prodotti", DistributoriAssociatiProdotti);
		Vue.component("seleziona-distributori-prodotti", SelezionaDistributoriProdotti);
		Vue.component("testicarrello-prodotti", TestiCarrelloProdotti);
		Vue.component("prodotti-correlati", ProdottiCorrelati);
		Vue.component("prodotti-upgrade", ProdottiUpgrade);
		Vue.component("scheda-prodotto", SchedaProdotto);
		Vue.component("scheda-polizza", SchedaPolizza);
		Vue.component("prodotti-associati-distributori", ProdottiAssociatiDistributori);
		Vue.component("prodotti-widget-associati-distributori", ProdottiWidgetAssociatiDistributori);
		Vue.component("ass-stato-gruppoStato", AssStatoGruppoStato);
		Vue.component("impostazioni", Impostazioni);
		Vue.component("popup-carrello", PopupCarrello);
		Vue.component("tipologie-prodotti", TipologieProdotti);
		Vue.component("eta", Eta);
		Vue.component("fasce-eta", FasceEta);
		Vue.component("parametri", Parametri);
		Vue.component("tipi-parametri", TipiParametri);
		Vue.component("tipi-parametri-valori", TipiParametriValori);
		Vue.component("informazioni-generiche", InformazioniGeneriche);

		Vue.component("multiselect-desc", MultiselectDesc);

		Vue.component("associa-stati", AssociaStati);
		Vue.component("associazione-stati", AssociazioneStati);

		Vue.component("associa-stati-fornitore", AssociaStatiFornitore);
		Vue.component("associazione-stati-fornitore", AssociazioneStatiFornitore);

		Vue.component("associa-stati-polizza", AssociaStatiPolizza);
		Vue.component("associazione-stati-polizza", AssociazioneStatiPolizza);
		
		Vue.component("associazione-nazionalita", AssociazioneNazionalita);
		Vue.component("associa-nazionalita", AssociaNazionalita);

		
		Vue.component("associa-nazionalita-fornitore", AssociaNazionalitaFornitore);
		Vue.component("associazione-nazionalita-fornitore", AssociazioneNazionalitaFornitore);
		
		Vue.component("configurazione-modifica-pratiche-dettaglio", ConfigurazioneModificaPraticheDettaglio);

		
		Vue.component("dichiarazioni", Dichiarazioni);
		Vue.component("info-prodotto-preventivo", InfoProdottoPreventivo);
		Vue.component("complementari", Complementari);
		Vue.component("griglia-partecipanti", GrigliaPartecipanti);
		Vue.component("griglia-ulteriori-dati", GrigliaUlterioriDati);
		
		Vue.component("carrello-dropdown-list", CarrelloDropDown);
		Vue.component("carrello-date-picker", CarrelloDatePicker);

		Vue.component("preview-template-pratica-multipla", PreviewPratica);

		Vue.component("columns-mapping", columnsMappingBase);

		Vue.component("kendo-numeric-textbox", NumericTextBox);
		Vue.component("v-jsoneditor", VJsoneditor);

		Vue.component("filter-mobile", FilterMobile);

		Vue.use(ButtonsInstaller);
		Vue.component("v-select", VSelect);
		Vue.use(VueLodash, { name: 'ld', lodash: lodash });
		Vue.use(datePicker);
		Vue.use(DateinputsInstaller);
		Vue.use(EditorInstaller);
		
		//Vue.use(VJsoneditor);

		//directives

		Vue.directive("auth-image", dir_auth_image);
	};
}

export default new main_registraion();
